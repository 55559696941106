//tracks user sign up 
export async function trackSignUp(data) {

  analytics.identify(data.id, {
    username: data.username,
    email: data.email,
    name: data.fullname,
    companyId: data.company,

  }
  );

  analytics.track("User Registered", {
    username: data.username,
    email: data.email
  }
  );
}

export async function trackLogIn(data) {
  
  analytics.identify(data.user.id, {
    username: data.user.username,
    email: data.user.email,
    companyId: data.user.company.id,  
    userType: data.user.user_type.type_name,
    emailValidated: data.user.email_validated,
    name: data.user.first_name,
  }
  /* , {
    integrations: {
      Amplitude: {
        session_id: Date.now()
      }
    }
  } */
  );

  analytics.track('User Logged In', {
    username: data.user.username,
    email: data.user.email,
    name: data.user.first_name + ' ' + data.user.paternal_lastname,
    companyId: data.user.company.id,
    company: data.user.company.comp_name,
  });

}

// export async function trackPageVisits(url) {  
//   analytics.page(url);
// }


export async function trackLogOut() {
  analytics.reset()
}

export async function trackLoggedInUser({ id, username, email }) {
  analytics.identify(id, {
    username: username,
    email: email,
  });
}

