import Router from "next/router";
import {
  getUserFromClientSide,
  isUserLoggedInFromClientSide,
  removeCookiesFromClient,
  setCookies
} from '../../lib/cookies';
import { trackLogOut } from "../../tracking/users";
import {
  ERROR_AT_LOGIN,
  SET_CHANGE_PASSWORD_MODAL,
  SET_ID_USER_TRACKING,
  SET_MESSAGE_AT_FORGOT_PASSWORD,
  SET_MESSAGE_AT_RESET_PASSWORD,
  SET_USER_FROM_CLIENT,
  WILL_LOGIN,
  WILL_LOGOUT
} from '../action_types';

// is user logged in?
export const isUserLoggedIn = (dispatch) => {
  if (isUserLoggedInFromClientSide()) {
    dispatch({ type: SET_USER_FROM_CLIENT, payload: { user: getUserFromClientSide() } })
  } else {
    Router.push('/login');
  }
}

// Auth actions
export const willLogIn = (dispatch, payload) => {
  const redirect = payload?.redirect ?? '/';
  setCookies(payload);
  dispatch({ type: WILL_LOGIN, payload: payload });
  const internalCompanyId = payload.user.internal_company_id !== null ? payload.user.internal_company_id : null
  if (internalCompanyId !== null && internalCompanyId !== '') {
    Router.push(redirect);
  } else {
    Router.push('/perfil');
  }
}

// Auth actions
export const willLogOut = (dispatch) => {
  removeCookiesFromClient();
  trackLogOut();
  dispatch({ type: WILL_LOGOUT });
}

// Error at login
export const setErrorAtLogin = (dispatch, payload) => {
  dispatch({ type: ERROR_AT_LOGIN, payload: payload });
}

// Message at forgot password
export const setMessageAtForgotPassword = (dispatch, payload) => {
  dispatch({ type: SET_MESSAGE_AT_FORGOT_PASSWORD, payload: payload });
}

// Message at reset password
export const setMessageAtResetPassword = (dispatch, payload) => {
  dispatch({ type: SET_MESSAGE_AT_RESET_PASSWORD, payload: payload });
}

// Set id user authInitialState
export const setIdUserTracking = (dispatch, payload) => {
  dispatch({ type: SET_ID_USER_TRACKING, payload: payload });
}

// Set showChangePasswordModal
export const setChangePasswordModal = (dispatch, payload) => {
  dispatch({ type: SET_CHANGE_PASSWORD_MODAL, payload: payload });
}
