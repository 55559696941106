import {
  ANSWER_SURVEY_QUESTION,
  CHANGE_SURVEY_QUESTION,
  IS_SURVEY_LOADING,
  SET_SURVEY_DATA,
  WILL_ANSWER_ESSENTIAL_DATA_LATER,
} from '../action_types';

// ANSWER SURVEY QUESTION
export const answerSurveyQuestion = (dispatch, payload) => {
  dispatch({ type: ANSWER_SURVEY_QUESTION, payload });
}

// CHANGE SURVEY QUESTION
export const changeSurveyQuestion = (dispatch, payload) => {
  dispatch({ type: CHANGE_SURVEY_QUESTION, payload });
}

// LOADING STATE FOR SURVEY
export const setSurveyLoading = (dispatch, payload) => {
  dispatch({ type: IS_SURVEY_LOADING, payload });
}

// SET TRUE WILL ANSWER LATER
export const handleWillAnswerLater = (dispatch) => {
  dispatch({ type: WILL_ANSWER_ESSENTIAL_DATA_LATER });
}

// SET QUESTIONS AND LENGTH OF SURVEY
export const setSurveyData = (dispatch, payload) => {
  dispatch({
    type: SET_SURVEY_DATA,
    payload: { questions: payload, totalQuestions: payload.length }
  });
}
