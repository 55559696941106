//Tracks user-led deliberate actions: clicks, searches, applications, etc

//track Clicks on programs
export async function trackClickProgram(payload) {
analytics.track('Clicked on program', {
    programId: payload?.course_id,
    program: payload?.course_name,
    university: payload?.inst_name,
    programType: payload?.program_type_id,
    programTypeName: payload?.program_type_name
  });
}
//search bar input
export async function trackSearchBar(searchValue) {
    analytics.track('Used search bar', {
        search: searchValue,
      } );
}
//added bookmark
export async function trackAddedBookmark(payload) {
    analytics.track('Added Bookmark', {
        programId: payload?.course_id,
        program: payload?.course_name,
        university: payload?.inst_name,
        programType: payload?.program_type_id,
        programTypeName: payload?.program_type_name
      } );
}
//deleted bookmark
export async function trackDeletedBookmark(payload) {
    analytics.track('Deleted Bookmark', {
        programId: payload?.course_id,
        program: payload?.course_name,
        university: payload?.inst_name,
        programType: payload?.program_type_id,
        programTypeName: payload?.program_type_name
      } );
}

//track sent applications
export async function trackSentApplications(data) {
    analytics.track('Submitted Application', {
        applicationId: data.id,
        program: data.program_name,
        university: data.program_institution,
        company: data.company,
        companyId: data.user.company
      } );
}

//track deleted applications
export async function trackDeletedApplications(data) {
    analytics.track('Deleted Application', {
        program: data.program_name,
        university: data.program_institution,
        applicationId: data.id,
        company: data.company,
        companyId: data.user.company
      } );
}

//track contact with coach -  whatsapp 
export async function trackWhatsappInteraction(user,section) {

    analytics.track('Requested Coach Support: Whatsapp', {
        username: user?.username,
        email: user?.email,
        coach: user?.coach?.coach_firstname+' '+user?.coach?.coach_lastname,
        section
    } );
}

export async function trackWhatsappSignup(action,section) {

  analytics.track('Requested Coach Support: Whatsapp signup', {
      section,
      action
  } );
}

//track contact with coach -  whatsapp 
// export async function trackScheduleCall(user) {
//     analytics.track('Requested Schedule Call', {

//       } );
// }


//track ask for pricing via whatsapp 
// export async function trackPricingRequest(course, user) {
//     analytics.track('Requested Pricing: Whatsapp', {
//         coachName: user.coach.coach_firstname+' '+user.coach.coach_lastname,
//         program: course.course_name,
//         university: course.university.inst_name,
//         pricing: course.course_pricing
//       } );
// }
//track ask for pricing via Email 
// export async function trackPricingRequestEmail(course) {
//   analytics.track('Requested Pricing: Email', {
//       programId: course.id,
//       program: course.course_name,
//       university: course.university.inst_name,
//       pricing: course.course_pricing
//     } );
// }


//track completed survey
export async function trackSurveyCompletion(data) {
          {data.user_response.map((data) => {
            return (
              analytics.track('Submitted Survey Answer', {
                question: data.question_text,
                answer: data.answer
              } )
            ) 
        })}    

}

//track submitted phone number
export async function trackPhone(data) {
        analytics.track('Updated Profile', {
            areaCode: data.data.calling_country_code, 
            phone: data.data.user_phone
           } )
 
 }
 
//track support messages to support@vincoed.com or coach
 export async function trackSupportMessage(message) {
    analytics.track('Sent support message', {
        supportMessage: message
       } )

}

// export async function trackFreeCourseClick(course) {

//     analytics.track('Clicked on Free Course', {
//         courseId: course.id,
//         course: course.freecourse_name,
//         duration: course.freecourse_duration,
//       } );
// }

export async function trackReferral(data) {
    analytics.track('Referred a co-worker', {
          originEmail: data.origin.email,
          targetEmail: data.target.email,
          targetUserType: data.target.user_type,
          companyId: data.origin.company,
        } );
  }    

  //track tabs within Programas page
  // export async function trackTabs(tabName) {
  //   analytics.track('Clicked on Tab', {tab: tabName} );
  // }  

    //track External Link
    export async function trackExternalBenefit(externalBenefit) {
      analytics.track('Clicked on External Link', {
            benefitName: externalBenefit.name,
            company: externalBenefit.company,
            benefitUrl: externalBenefit.benefit
        } );
    }
    
//track External Link
export async function trackSearchFilterGlobal(search) {

  analytics.track('Searched on search Global', {
        idUser: search.id,
        userName: search.name,
        searchValue:search.value
    } );
} 



