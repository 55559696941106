import {
  SET_SIGNUP_FIELD,
  SET_ERROR_AT_SIGNUP_FIELD,
  SET_COMPANIES_DROPDOWN,
  SET_SIGNUP_STEPS,
  SIGNUP_NEXT_STEP,
  SIGNUP_BACK_STEP,
  SET_STEP_FORM_VALIDATION,
  SET_USER_TYPES
} from '../action_types';

// SIGNUP FORM IN STEPS
export const setSignupField = (dispatch, payload) => {
  dispatch({ type: SET_SIGNUP_FIELD, payload: payload });
}

// SET ERROR IN FIELD
export const setErrorAtSignupField = (dispatch, payload) => {
  dispatch({ type: SET_ERROR_AT_SIGNUP_FIELD, payload: payload });
}

// SET IF FORM IN STEP IS VALID {form name, form value}
export const setSignupFormValidation = (dispatch, payload) => {
  dispatch({ type: SET_STEP_FORM_VALIDATION, payload: payload });
}

// SET COMPANIES
export const setCompaniesDropdown = (dispatch, payload) => {
  dispatch({ type: SET_COMPANIES_DROPDOWN, payload: payload });
}

// SET USER TYPES
export const setUserTypes = (dispatch, payload) => {
  dispatch({ type: SET_USER_TYPES, payload: payload });
}

// Set steps in form {[steps]}
export const setSignupSteps = (dispatch, payload) => {
  dispatch({ type: SET_SIGNUP_STEPS, payload: payload })
};

// Handle next step
export const handleSignupNext = (dispatch, payload) => {
  dispatch({ type: SIGNUP_NEXT_STEP, payload: payload })
};

// Handle back step
export const handleSignupBack = (dispatch, payload) => {
  dispatch({ type: SIGNUP_BACK_STEP, payload: payload })
};
