import styled from "styled-components";
import color from "color";

export const ThemeStyles = styled.div`
    .MuiFormLabel-root.Mui-focused {
        ${(props) => (props.color ? "color: ".concat(props.color, ";") : null)};
    }
    .MuiInput-underline:after {
        ${(props) =>
            props.color
                ? "border-bottom: 2px solid ".concat(props.color, ";")
                : null};
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
        ${(props) => (props.color ? "color: ".concat(props.color, ";") : null)};
    }
    .MuiButton-containedPrimary {
        ${(props) =>
            props.color ? "background-color: ".concat(props.color, ";") : null};
    }
    .MuiButton-containedPrimary:hover {
        ${(props) =>
            props.color
                ? "background-color: ".concat(
                      color(props.color).blacken(1).hex(),
                      ";"
                  )
                : null};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        ${(props) =>
            props.color ? "border-color: ".concat(props.color, ";") : null};
    }
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: #ff1744;
    }
    .MuiFormLabel-root.Mui-error {
        color: #ff1744;
    }
`;
