import {
  CLOSE_SNACKBAR,
  IS_LOADING,
  OPEN_SNACKBAR,
  START_LOGIN_PROGRESS,
  FINISH_LOGIN_PROGRESS,
  RESET_LOGIN_PROGRESS,
  IS_LOGIN_SUCCESSFULL,
  GET_DATA_CURRENCY,
  RESET_LOGIN_STATE,
  TOGGLE_MAIN_MENU
} from '../action_types';

// LOGIN PROGRESS
export const setLoginProgress = (dispatch, payload) => {
  dispatch({ type: START_LOGIN_PROGRESS, payload: payload });
}

export const finishLoginProgress = (dispatch) => {
  dispatch({ type: FINISH_LOGIN_PROGRESS });
}

export const resetLoginProgress = (dispatch) => {
  dispatch({ type: RESET_LOGIN_PROGRESS });
}

export const isLoginSuccessfull = (dispatch) => {
  dispatch({ type: IS_LOGIN_SUCCESSFULL });
}

export const getDataCurrency = (dispatch, payload) => {
  dispatch({ type: GET_DATA_CURRENCY, payload: payload });
}

export const resetLoginState = (dispatch) => {
  dispatch({ type: RESET_LOGIN_STATE });
}
// FINISH LOGIN PROGRESS

// isOpen: true, message, severity, autoHideDuration: null || number
export const openSnackbar = (dispatch, payload) => {
  dispatch({ type: OPEN_SNACKBAR, payload: payload });
}

export const closeSnackbar = (dispatch) => {
  dispatch({ type: CLOSE_SNACKBAR });
}

// loading: true/false
export const isLoading = (dispatch, payload) => {
  dispatch({ type: IS_LOADING, payload: payload });
}

export const toggleMainMenu = (dispatch) => {
  dispatch({ type: TOGGLE_MAIN_MENU });
}