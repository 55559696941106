import {
  WILL_CLOSE_DIALOG,
  WILL_DELETE_APPLICATION,
  DUPLICATED_ENTRY
} from '../action_types';

// Delete Application by Id
export const willDeleteApplication = (dispatch, payload) => {
  dispatch({ type: WILL_DELETE_APPLICATION, payload: payload });
}

export const willCloseDialog = (dispatch) => {
  dispatch({ type: WILL_CLOSE_DIALOG });
}

export const setDuplicatedEntry = (dispatch, payload) => {
  dispatch({ type: DUPLICATED_ENTRY, payload: payload })
}