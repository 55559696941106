import { trackClickProgram, trackAddedBookmark, trackDeletedBookmark } from '../../tracking/userActions';
import {
  TOGGLE_MOBILE_FILTERS,
  SET_COURSES,
  SET_FILTERED_COURSES,
  SET_COURSE_TYPES,
  SET_FILTER_CHECKBOX,
  SET_PAGINATION,
  DELETE_FILTER_CHECKBOX,
  SET_UNIVERSITIES,
  SELECT_COURSE,
  SET_COURSES_LOADING,
  SET_SAVED_COURSES,
  SET_RECOMMENDED_COURSES,
  ADD_SAVED_COURSE,
  DELETE_SAVED_COURSE,
  SET_SEARCH_BAR_VALUE,
  SET_SEARCH_BAR_PAGINATION,
  SET_SEARCH_BAR_QUERY,
  SET_SEARCH_BAR_BY,
  SET_SEARCH_BAR_LOADING,
  SET_FOUND_COURSES,
  SET_FOUND_COURSES_PAGE,
  SET_FAVORITE_SEARCHBAR_QUERY,
  SET_FAVORITE_FILTERED_COURSES,
  SET_FAVORITE_SEARCHBAR_NUMBEROFPAGES,
  SET_FILTER_BY_DATE,
  SET_FILTER_BY_START_DATE,
  SET_COURSES_COUNT,
  CHANGE_PAGE,
  RESET_REGISTER_DATE,
  RESET_START_DATE,
  SET_RECURRING_DATE,
  RESET_RECURRING_DATE,
  SET_SUBJECT_AREA_TAGS,
  ADD_SUBJECT_AREA_TAGS_FILTER,
  DELETE_SUBJECT_AREA_TAGS_FILTER,
  RESET_ALL_FILTERS,
  SET_COURSE_TYPE_FROM_INDEX,
  RESET_STARTED_SAVING,
  REMOVE_REDIRECT_FROM_INDEX
} from '../action_types';

export const toggleMobileFilters = (dispatch) => {
  dispatch({ type: TOGGLE_MOBILE_FILTERS });
}

export const setUniversities = (dispatch, payload) => {
  dispatch({ type: SET_UNIVERSITIES, payload: payload });
}

export const setCourseTypes = (dispatch, payload) => {
  dispatch({ type: SET_COURSE_TYPES, payload: payload });
}

//payload id, filterType
export const setFilterType = (dispatch, payload) => {
  dispatch({ type: SET_FILTER_CHECKBOX, payload: payload });
}
//payload id, filterType
export const deleteFilterType = (dispatch, payload) => {
  dispatch({ type: DELETE_FILTER_CHECKBOX, payload: payload });
}

export const setCourses = (dispatch, payload) => {
  dispatch({ type: SET_COURSES, payload: payload });
}

export const setSavedCourses = (dispatch, payload) => {
  dispatch({ type: SET_SAVED_COURSES, payload: payload });
}

export const setRecommendedCourses = (dispatch, payload) => {
  dispatch({ type: SET_RECOMMENDED_COURSES, payload: payload });
}

export const addSavedCourse = (dispatch, payload) => {
  dispatch({ type: ADD_SAVED_COURSE, payload: payload });
  trackAddedBookmark(payload);
}

export const deleteSavedCourse = (dispatch, payload) => {
  dispatch({ type: DELETE_SAVED_COURSE, payload: payload });
  trackDeletedBookmark(payload);
}

export const filterCourses = (dispatch, payload) => {
  dispatch({ type: SET_FILTERED_COURSES, payload: payload });
}

export const setPagination = (dispatch, payload) => {
  const { coursesCount, sortBy, query } = payload;
  if (coursesCount > sortBy) {
    const numberOfPages = Math.ceil(coursesCount / sortBy);
    dispatch({ type: SET_PAGINATION, payload: { numberOfPages: numberOfPages, query: query, totalCourses: coursesCount } });
  } else {
    dispatch({ type: SET_PAGINATION, payload: { numberOfPages: 1, query: query, totalCourses: coursesCount } });
  }
}

export const selectCourse = (dispatch, payload) => {
  dispatch({ type: SELECT_COURSE, payload: payload });
  trackClickProgram(payload);
}

export const setCoursesLoading = (dispatch, payload) => {
  dispatch({ type: SET_COURSES_LOADING, payload: payload })
}

export const setSearchBarValue = (dispatch, payload) => {
  dispatch({ type: SET_SEARCH_BAR_VALUE, payload: payload })
}

export const setSearchBarPagination = (dispatch, payload) => {
  const { coursesCount, sortBy } = payload;
  if (coursesCount > sortBy) {
    const numberOfPages = Math.ceil(coursesCount / sortBy);
    dispatch({ type: SET_SEARCH_BAR_PAGINATION, payload: { numberOfPages: numberOfPages } });
  } else {
    dispatch({ type: SET_SEARCH_BAR_PAGINATION, payload: { numberOfPages: 1 } });
  }
}

export const setSearchBarQuery = (dispatch, payload) => {
  dispatch({ type: SET_SEARCH_BAR_QUERY, payload: payload })
}

export const setSearchBarBy = (dispatch, payload) => {
  dispatch({ type: SET_SEARCH_BAR_BY, payload: payload })
}

export const setSearchBarLoading = (dispatch, payload) => {
  dispatch({ type: SET_SEARCH_BAR_LOADING, payload: payload })
}

export const setFoundCourses = (dispatch, payload) => {
  dispatch({ type: SET_FOUND_COURSES, payload: payload })
}

export const setFoundCoursesPage = (dispatch, payload) => {
  dispatch({ type: SET_FOUND_COURSES_PAGE, payload: payload })
}

export const setFavoriteSearchBarQuery = (dispatch, payload) => {
  dispatch({ type: SET_FAVORITE_SEARCHBAR_QUERY, payload: payload })
}

export const filterSavedCoursesByQuery = (dispatch, payload) => {
  dispatch({ type: SET_FAVORITE_FILTERED_COURSES, payload: payload })
}

export const setSavedCoursesNumberOfPages = (dispatch, payload) => {
  dispatch({ type: SET_FAVORITE_SEARCHBAR_NUMBEROFPAGES, payload: payload })
}

export const setRegisterDateInterval = (dispatch, payload) => {
  dispatch({ type: SET_FILTER_BY_DATE, payload: payload });
}

export const setRegisterStartDateInterval = (dispatch, payload) => {
  dispatch({ type: SET_FILTER_BY_START_DATE, payload: payload });
}

export const setCoursesCount = (dispatch, payload) => {
  dispatch({ type: SET_COURSES_COUNT, payload: payload });
}

export const changePage = (dispatch, payload) => {
  dispatch({ type: CHANGE_PAGE, payload: payload });
}

export const resetRegisterDate = (dispatch) => {
  dispatch({ type: RESET_REGISTER_DATE });
}

export const resetStartDate = (dispatch) => {
  dispatch({ type: RESET_START_DATE });
}

export const setRecurringDate = (dispatch, payload) => {
  dispatch({ type: SET_RECURRING_DATE, payload: payload });
}

export const resetRecurringDate = (dispatch) => {
  dispatch({ type: RESET_RECURRING_DATE });
}

export const setSubjectAreaTags = (dispatch, payload) => {
  dispatch({ type: SET_SUBJECT_AREA_TAGS, payload });
}

export const addSubjectAreaTagsFilter = (dispatch, payload) => {
  dispatch({ type: ADD_SUBJECT_AREA_TAGS_FILTER, payload });
}

export const deleteSubjectAreaTagsFilter = (dispatch, payload) => {
  dispatch({ type: DELETE_SUBJECT_AREA_TAGS_FILTER, payload });
}

export const resetAllFilters = (dispatch) => {
  dispatch({ type: RESET_ALL_FILTERS });
}

export function setProgramTypeFilterFromIndex({ dispatch, payload }) {
  dispatch({ type: SET_COURSE_TYPE_FROM_INDEX, payload: payload });
}

export function removeRedirectFromIndex(dispatch) {
  dispatch({ type: REMOVE_REDIRECT_FROM_INDEX });
}

export const resetBookmarksHandler = (dispatch) => {
  dispatch({ type: RESET_STARTED_SAVING });
}
